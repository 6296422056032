import React, { useState } from 'react';
import './present.css';

const LevelThreeEnd: React.FC = () => {
    const [step, setStep] = useState<number>(0);

    const nextStep = () => {
        setStep(step + 1);
    };

    const images = {
        image1: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/level-ends%2Flevel-4-end%2Fpt1.jpeg?alt=media&token=d0e91b14-4086-4fdf-ac0c-99bb6b2ce93f',
        image2: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/level-ends%2Flevel-4-end%2Fpt2.jpeg?alt=media&token=e196d6f1-7466-4364-aec6-f92d76854596',
        image3: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/level-ends%2Flevel-4-end%2Fpt3.jpeg?alt=media&token=be933120-bbc2-455e-989c-3ae3876a05a0'
    }

    return (
        <div className="flex flex-col items-center present text-center pb-10 m-[-1rem] p-4 bg-slate-900">
            <h1>Eine kleine Geschichte :)</h1>
            <p>Du hast Level 4 beendet.</p>
            <div className="flex flex-col items-center">
                {step >= 1 && (
                    <img src={images.image1} className="my-4 w-[20rem] pointer-events-none" />
                )}
                {step >= 2 && (
                    <img src={images.image2} className="my-4 w-[20rem] pointer-events-none" />
                )}
                {step >= 3 && (
                    <img src={images.image3} className="my-4 w-[20rem] pointer-events-none" />
                )}
            </div>
            {step <= 2 && (
                <button className="w-48 p-2 mx-8 mt-4 font-home font-bold text-1xl bg-jam-glass border-1 border-white rounded-xl shadow-2xl" onClick={nextStep}>Weiter {`(${step + 1}/13)`}</button>
            )}
        </div>
    );
};

export default LevelThreeEnd;