// StageCreator.tsx
import React, { useState, useEffect, ChangeEvent } from 'react';
import { doc, setDoc, getDocs, collection, deleteDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, imageDB } from '../../../firebaseconfig';
import imageIcon from '../../../assets/rewards/image-icon.png';
import videoIcon from '../../../assets/rewards/video-icon.png';
import textIcon from '../../../assets/rewards/text-icon.png';
import audio from '../../../assets/rewards/audio-icon.png';
import questionMark from '../../../assets/rewards/question-mark-icon.png';
import flameIcon from '../../../assets/rewards/flame-icon.png';
import levelEnd from '../../../assets/rewards/level-end.png';
import present from '../../../assets/rewards/gift-icon.png';
import check from '../../../assets/check.svg';
import { RewardStage } from '../rewards/reward-models';
import Loading from '../../utils/loading/loading';


interface StageCreatorProps {
  levelNumber: string;
  levelName: string;
}

type Types = 'image' | 'video' | 'video-file' | 'text' | 'audio' | 'secret' | 'present' | 'link' | 'hot' | 'hot-video' | 'levelEnd' | 'levelThreeEnd' | 'levelFourEnd';

const StageCreator: React.FC<StageCreatorProps> = ({ levelNumber, levelName }) => {
  const [stageNumber, setStageNumber] = useState<string>('');
  const [type, setType] = useState<Types>('image');
  const [burned, setBurned ] = useState<boolean>(false);
  const [resource, setResource] = useState<string>('');
  const [file, setFile] = useState<File | null>(null);
  const [stages, setStages] = useState<RewardStage[]>([]);
  const [selectedStage, setSelectedStage] = useState<RewardStage | null>(null);
  const [ui, setUi] = useState<string>('allStages');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchStages();
    if (!stages.length) {
      setStageNumber('1');
    }
  }, [levelNumber]);

  const fetchStages = async () => {
    try {
      const stagesCollectionRef = collection(db, `bear-rewards/level${levelNumber}/reward-stages-level${levelNumber}`);
      const stagesSnapshot = await getDocs(stagesCollectionRef);
      const stagesData = stagesSnapshot.docs.map(doc => doc.data() as RewardStage);
      setStages(stagesData);
    } catch (error) {
      console.error('Error fetching stages:', error);
    }
  };

  const createOrUpdateStage = async () => {
    if (!stageNumber) {
      alert('Please enter the stage number.');
      return;
    }

    setIsLoading(true);

    let resourceURL = resource;
    if (file) {
      try {
        const storageRef = ref(imageDB, `bear-rewards/${type}s/level${levelNumber}_stage${stageNumber}`);
        await uploadBytes(storageRef, file);
        resourceURL = await getDownloadURL(storageRef);
      } catch (error) {
        console.error('Error uploading file:', error);
        alert('Failed to upload file. Please try again.');
        setIsLoading(false);
        return;
      }
    }

    const stageData: RewardStage = {
      level: Number(levelNumber),
      stage: Number(stageNumber),
      type: type,
      burned: false,
      ressource: resourceURL,
    };

    try {
      const stageDocRef = doc(
        db,
        `bear-rewards/level${levelNumber}/reward-stages-level${levelNumber}`,
        `stage${stageNumber}`
      );
      await setDoc(stageDocRef, stageData);
      alert('Stage saved successfully!');
      fetchStages();
      setSelectedStage(stageData);
      // Reset fields if creating new stage
      if (!selectedStage) {
        setStageNumber('');
        setType('image');
        setResource('');
        setFile(null);
      }
      setUi('allStages');
      setResource(resourceURL)
      setIsLoading(false);
    } catch (error) {
      console.error('Error saving stage:', error);
      alert('Failed to save stage. Please try again.');
      setIsLoading(false);
    }
  };

  const handleStageSelect = (stage: RewardStage) => {
    setSelectedStage(stage);
    setStageNumber(stage.stage.toString());
    setType(stage.type);
    setResource(stage.ressource);
    setBurned(stage.burned);
    setUi('manageStages');
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const deleteStage = async () => {
    if (!selectedStage) return;
    try {
      await deleteDoc(doc(db, `bear-rewards/level${levelNumber}/reward-stages-level${levelNumber}`, `stage${selectedStage.stage}`));
      alert('Stage deleted successfully!');
      fetchStages();
      setSelectedStage(null);
      setStageNumber('');
      setType('image');
      setResource('');
      setFile(null);
      setUi('allStages');
    } catch (error) {
      console.error('Error deleting stage:', error);
      alert('Failed to delete stage. Please try again.');
    }
  };

  const uiCreateStage = (
    <div className="flex flex-col w-full">
        <button className="p-2 mb-4 font-home font-bold text-1xl bg-gray-900 rounded-xl shadow-2xl" onClick={() => handleBack()}>Zurück zu: Alle Level {levelNumber} Stages</button>
        <span className="mb-2 mt-4 pt-4 border-t border-1 border-white">Stage Nummer</span>
        <input
        className="text-black rounded-md p-2 mb-2"
          type="number"
          value={stageNumber}
          onChange={(e) => setStageNumber(e.target.value)}
          placeholder="Stage Number"
        />
        <span className="mb-2">Stage Inhalt</span>
        <select className="text-black rounded-md p-2 mb-2" value={type} onChange={(e) => setType(e.target.value as Types)}>
          <option value="image">Bild</option>
          <option value="video-file">Video</option>
          <option value="text">Text</option>
          <option value="link">Link</option>
          <option value="hot">Nude Bild (Flamme)</option>
          <option value="hot-video">Nude Video (Flamme)</option>
        </select>
        {['image', 'audio', 'hot', 'hot-video', 'video-file'].includes(type) ? (
          <>
            <input type="file" onChange={handleFileChange} />
          </>
        ) : (
          <textarea
          className="text-black rounded-md p-2 mb-2"
            value={resource}
            onChange={(e) => setResource(e.target.value)}
            placeholder="URL oder Text"
          />
        )}
        <div className="flex items-center mt-2 mb-2">
          <div className="w-full rounded-t-lg">
            <div className="flex items-center">
                <input 
                  type="checkbox"
                  id="burned"
                  checked={burned}
                  onChange={(e) => setBurned(e.target.checked)}
                  className="w-4 h-4 text-blue-600 bg-gray-100"
                />
                <label htmlFor="burned" className="w-full py-3 ms-2 text-1xl font-medium">Gesperrt</label>
            </div>
        </div>
        </div>
        
        <button className="p-2 mt-4 font-home font-bold text-1xl bg-gray-900 rounded-xl shadow-2xl" onClick={createOrUpdateStage}>
          {selectedStage ? 'Aktualisieren' : 'Erstellen'}
        </button>
        {selectedStage && (
          <button className="p-2 mt-4 font-home font-bold text-1xl bg-gray-900 rounded-xl shadow-2xl" onClick={deleteStage}>Stage Löschen</button>
        )}

        <span className="mb-2 mt-4 pt-4 border-t border-1 border-white font-bold">Vorschau</span>
        { ['image', 'hot'].includes(type) && resource && <img src={resource} alt="Preview" /> }
        {
            ['video-file', 'hot-video'].includes(type) && resource && (
                <video controls>
                    <source src={resource} type="video/mp4" />
                </video>
            )
        }
      </div>
  );

  function mapRewardTypeToIcon(type: RewardStage['type']): string {
    switch (type) {
        case 'image':
            return imageIcon;
        case 'video':
            return videoIcon;
        case 'video-file':
            return videoIcon;
        case 'text':
            return textIcon;
        case 'link':
            return textIcon;
        case 'audio':
            return audio;
        case 'secret':
            return questionMark;
        case 'hot':
            return flameIcon;
        case 'hot-video':
            return flameIcon;
        case 'levelEnd':
            return levelEnd;
        case 'levelThreeEnd':
            return flameIcon;
        case 'present':
            return present;
        default:
            return imageIcon;
    }
}

  const uiAllStages = (
    <>
        <h3 className="text-center text-2xl mt-5 mb-4">Alle Stages</h3>
        <div className='flex gap-2 flex-wrap gap-y-8'>
            {stages.map((stage, index) => (
                <div key={index} className="item relative flex justify-center w-[5.375rem] h-[5.375rem]" onClick={() => handleStageSelect(stage)}>
                    <div className={`z-10 relative flex items-center justify-center bg-home-tile w-[5.375rem] h-[5.375rem] rounded-[1.25rem] border-solid border-[0.25rem] border-[#754701] shadow-md`}>
                        <img src={mapRewardTypeToIcon(stage.type)} alt="reward" className={`w-[4rem]`}/>
                    </div>
                    <div className={`z-0 absolute flex justify-center items-end bottom-0 h-16 w-2/3 mb-[-1.25rem] text-white font-bold rounded-b-[1.25rem] ${stage.burned ? 'bg-yellow-700' : 'bg-green-700'}`}>
                        <div className="flex gap-1">{stage.stage} { stage.burned && <img src={check} alt="checkmark" /> }</div>
                    </div>
                </div>
            ))}
        </div>
        <button className="p-2 mt-10 font-home font-bold text-1xl bg-gray-900 rounded-xl shadow-2xl" onClick={() => handleNewStage()}>Neue Stage erstellen</button>
    </>
  );

  const handleNewStage = () => {
    setUi('manageStages');
    setSelectedStage(null);
    const nextStageNumber = stages.length ? stages[stages.length - 1].stage + 1 : 1;
    setStageNumber(nextStageNumber.toString());
  };

  const handleBack = () => {
    setUi('allStages');
    setSelectedStage(null);
  }

  return isLoading ? <Loading loading={isLoading} /> : (
    <div className="w-11/12">
        {
            ui === 'allStages' && uiAllStages
        }
        {
            ui === 'manageStages' && uiCreateStage
        }
    </div>
  );
};

export default StageCreator;
