import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import glass from '../../../assets/home/jam.png';
import rewards from '../../../assets/home/rewards.png';
import gaming from '../../../assets/home/gaming.png';
import commingSoon from '../../../assets/home/comming-soon.png';
import level from '../../../assets/home/level-sign.png';
import stage from '../../../assets/home/stage-sign.png';
import streak from '../../../assets/home/streak-sign.png';
import heart from '../../../assets/home/win-heart.png';
import bearMail from '../../../assets/home/bear-mail.png';
import mailbox from '../../../assets/home/mailbox.png';
import presentMice from '../../../assets/home/present_mice.png';
import newIcon from '../../../assets/home/new.png';
import { useUserProgress } from '../../../state/use-user-progress';
import { getEnvKey } from '../../../utils/getEnvKey';
import ActivitySwitch from './activity-switch';

const Home: React.FC = () => {
    
    const navigate = useNavigate();
    const { userProgress, refreshLevelData } = useUserProgress();


    const refreshAndScrollToTop = useCallback(() => {
        console.log('scrolling to top');
        refreshLevelData().then(() => {
            window.scrollTo(0, 0);
        });
    }, [refreshLevelData]);

    useEffect(() => {
        refreshAndScrollToTop();
    }, []);

    return (
        <>
            <div className="fixed top-0 left-0 h-screen w-full bg-home bg-cover"></div>
            <div className="fixed flex flex-col justify-start items-center w-full h-28 px-6 pt-2 bg-home-header z-50">
                <div className="flex justify-between max-w-[34.375rem] text-3xl text-gray-950 font-bold gap-10">
                    <div className="flex justify-center items-center gap-2">
                        <img src={level} alt="streakFlame" className="h-12"/>
                        <span>{userProgress.level}</span>
                    </div>
                    <div className="flex justify-center items-center gap-2">
                        <img src={stage} alt="streakFlame" className="h-12"/>
                        <span>{userProgress.stage}</span>
                    </div>
                    <div className="flex justify-center items-center gap-2">
                        <img src={streak} alt="streakFlame" className="h-12"/>
                        <span>{userProgress.streak}</span>
                    </div>
                </div>
            </div>
            <div className="relative flex flex-col items-center px-4 pb-4">
                <div id="home-content" className="flex flex-col mt-28">
                    <div
                        className="flex flex-col mb-8 px-5 py-2.5 text-[3rem] text-center border-solid border-[0.25rem] border-[#754701] rounded-[1.25rem] bg-home-greeting *:font-bold *:leading-[3.1875rem]"
                        onClick={() => { if(getEnvKey('ENV') === 'staging') { navigate('/reset') } }}
                    >
                        <div className="flex items-center justify-center gap-4">
                            <span>Hallo</span>
                            <img src={heart} alt="heart" className="h-8"/>
                        </div>
                        <span className="bg-gradient-to-r from-blue-600 via-green-500 to-indigo-400 inline-block text-transparent bg-clip-text">
                            Mäuschen
                        </span>
                    </div>
                    <ActivitySwitch className='' />

                    <div className="grid grid-cols-2 gap-4 w-full pb-4">
                        <div 
                            className="relative flex items-center justify-center bg-home-tile w-40 h-40 pb-8 rounded-[1.25rem] border-solid border-[0.25rem] border-[#754701] shadow-md cursor-pointer"
                            onClick={() => navigate('/jam-glass')}
                        >
                            <img src={glass} alt="spinner" className="h-28"/>
                            <span className="absolute bottom-3 text-orange-300 font-bold text-xl">Marmelade</span>
                        </div>
                        <div
                            className="relative flex items-center justify-center bg-home-tile w-40 h-40 pb-8 rounded-[1.25rem] border-solid border-[0.25rem] border-[#754701] shadow-md cursor-pointer"
                            onClick={() => navigate('/rewards')}
                        >
                            <img src={rewards} alt="spinner" className="h-28"/>
                            <span className="absolute bottom-3 text-orange-300 font-bold text-xl">Belohnungen</span>
                        </div>
                        <div
                            className="relative flex items-center justify-center bg-home-tile w-40 h-40 pb-8 rounded-[1.25rem] border-solid border-[0.25rem] border-[#754701] shadow-md cursor-pointer"
                            onClick={() => navigate('/bear-mail-form')}
                        >
                            <img src={bearMail} alt="spinner" className="h-28"/>
                            <span className="absolute bottom-3 text-orange-300 font-bold text-xl">Bärchen Post</span>
                        </div>
                        <div
                            className="relative flex items-center justify-center bg-home-tile w-40 h-40 pb-8 rounded-[1.25rem] border-solid border-[0.25rem] border-[#754701] shadow-md cursor-pointer"
                            onClick={() => navigate('/bear-mail-list')}
                        >
                            <img src={mailbox} alt="spinner" className="h-28"/>
                            <span className="absolute bottom-3 text-orange-300 font-bold text-xl">Postfach</span>
                        </div>
                        <div
                            className="relative flex items-center justify-center bg-home-tile w-40 h-40 pb-8 rounded-[1.25rem] border-solid border-[0.25rem] border-[#754701] shadow-md cursor-pointer"
                            onClick={() => navigate('/maus-upload')}
                        >
                            <img src={presentMice} alt="spinner" className="h-28"/>
                            <span className="absolute bottom-3 text-orange-300 font-bold text-xl">Maus Upload</span>
                        </div>
                        <div
                            className="relative flex items-center justify-center bg-home-tile w-40 h-40 pb-8 rounded-[1.25rem] border-solid border-[0.25rem] border-[#754701] shadow-md cursor-pointer"
                            onClick={() => navigate('/mini-games')}
                        >
                            <img src={gaming} alt="spinner" className="h-28"/>
                            <span className="absolute bottom-3 text-orange-300 font-bold text-xl">Mini Games</span>
                        </div>
                        <div
                            className="relative flex items-center justify-center bg-home-tile w-40 h-40 pb-8 rounded-[1.25rem] border-solid border-[0.25rem] border-[#754701] shadow-md cursor-pointer"
                            onClick={() => navigate('/home')}
                        >
                            <img src={commingSoon} alt="spinner" className="h-28"/>
                            <span className="absolute bottom-3 text-orange-300 font-bold text-xl">In Arbeit ...</span>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default Home;