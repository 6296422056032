import React, { useState, useEffect } from 'react';
import Card, { CardType } from './memory-card';
import "./memory-game.css";
import ScrollingHeader from '../../header/scrolling-header/scrolling-header';
import BackButton from '../../utils/back-button/back-button';
import { useUserProgress } from '../../../state/use-user-progress';
import { useNavigate } from 'react-router-dom';

const cardImagesSet1 = [
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/memory%2F0f5a460b-35ac-4315-9308-d569380eab61-min.jpg?alt=media&token=3e8585f6-ba34-4b28-8e00-dd63b6ff84b5' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/memory%2F7e3a13b8-acb5-4046-820b-d50313ce6306-min.jpg?alt=media&token=6de3a34e-c557-4a42-acb3-7ae080cb7b50' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/memory%2F8A2AF662-18DE-460E-A736-33FB5D750083-min.JPG?alt=media&token=b17702d8-ab1c-4b12-b654-314f2f3baa72' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/memory%2FIMG_2610-min.jpg?alt=media&token=2b98ab9e-b11c-464b-97c7-9340b395b16b' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/memory%2FIMG_3185-min.JPG?alt=media&token=3535f166-9ab8-49e2-9788-20ffd71bfcc2' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/memory%2FIMG_3863-min.jpg?alt=media&token=0bd6a87e-b466-4d0f-a4a9-eecab0236fa1' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/memory%2FIMG_3931-min.jpg?alt=media&token=2a631698-1b0c-407f-b21a-cb4613e47108' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/memory%2FIMG_3997-min.jpg?alt=media&token=90b8dc80-9307-451a-a7ef-260663094bb8' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/memory%2FIMG_3361_schloss.png?alt=media&token=5798c027-c710-4f4c-91bb-61aa9ffe9e33' },
];

const cardImagesSet2 = [
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/hot-memory%2FB015B03F-DD8D-4794-B4D7-02630CEAAE9C%20(1).jpeg?alt=media&token=85b7ce1a-0b80-4483-878c-9695ee5f5e5c' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/level-ends%2Flevel-3-end%2FIMG_5044.jpg?alt=media&token=ced6c3f5-5b23-46db-b826-b56950e6d3ab' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/level-ends%2Flevel-3-end%2FIMG_4454.jpg?alt=media&token=5bb6f0ac-7ae4-4863-9baa-3f6ab995e66c' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/level-ends%2Flevel-3-end%2FIMG_5050.jpg?alt=media&token=b7c1d747-4c64-4a4d-8a3c-08d06b05d187' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/level-ends%2Flevel-3-end%2FIMG_5053.jpg?alt=media&token=bb2e09f0-e2e8-4399-848d-00479123c8c3' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/hot-memory%2FIMG_20171113_192441.jpg?alt=media&token=1a55578f-acd3-4cab-867c-6ec47311c1b5' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/hot-memory%2FIMG_20171113_192548.jpg?alt=media&token=d5e1a099-cf41-43e8-9d02-f67b60490734' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/hot-memory%2Fbear_hot_1.jpg?alt=media&token=ddf1c1e2-9369-4c82-b695-62d9bbc80fb0' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/hot-memory%2Fbear_hot_2.jpg?alt=media&token=81a4bd10-a080-4d0e-8459-6d9510180999' },
];

const cardImagesSet4 = [
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/hot-memory%2F8ae02bb2-7ca8-40cf-a477-542fba29bf6c.JPG?alt=media&token=4683f593-82a2-4d21-b8c1-5780293ab76a' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/hot-memory%2F9a8020e2-1951-43e5-84c1-59b8fc826530.JPG?alt=media&token=43852c92-5f0d-4dbc-9112-8e8a8757a560' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/hot-memory%2Ff689a4a4-499c-48e9-bf89-b9a2a05dc147.JPG?alt=media&token=9eded459-cfa7-4d85-aa40-0c8af4a32d0a' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/hot-memory%2Fff49a189-4568-4671-a7a3-bd5d7d59a887.JPG?alt=media&token=e54a119d-4d22-4c8b-9747-6216d2b06860' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/hot-memory%2FIMG_3585.jpg?alt=media&token=9456c648-306c-416a-b6bd-7e17d9f7fe4f' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/hot-memory%2F92c3ab0a-4f45-4dd3-b7af-29c65349f690.JPG?alt=media&token=a7c58804-21c0-42fa-804a-cebe45d6e92c' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/hot-memory%2Fb9a964af-e269-464c-8ec5-569d78ddacd4.JPEG?alt=media&token=742f1abb-56ab-45c5-87e3-e3d6e46cb1ab' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/hot-memory%2F9513fabd-ce19-4a0a-855f-213d15de5cf6.JPG?alt=media&token=2b05cb1d-752d-4841-b8bd-a712e750ae12' },
];

const cardImagesSet3 = [
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/bagger-memory%2F02217EC_01_300_rgb.jpg?alt=media&token=4b3d9432-fa87-4b45-9d41-464160003f1c' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/bagger-memory%2F1080x1080_EXC_EC350E_D8M_H__002__3652146.jpg?alt=media&token=a8f76ae1-069a-4c04-99ec-6338b276892b' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/bagger-memory%2F212565_221822_MBE800_Mini-Bagger_Schraeg.jpg?alt=media&token=b917cd7b-7bab-45ed-8bb7-04cfaa953783' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/bagger-memory%2F71Vn6yrAO3L.jpg?alt=media&token=fa1eccbd-52f7-4cdb-b830-806759746f37' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/bagger-memory%2FBagger_Kette.jpg.imagethumb.737.jpg?alt=media&token=90b0f00c-8213-4017-a0f9-8d43fe98603b' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/bagger-memory%2FBagger_Mini_301-6.jpg.imagethumb.737.jpg?alt=media&token=6ecc40c2-8a01-474e-8729-af3817798bb2' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/bagger-memory%2FKUBOTA-Mini-Kompakt-Bagger.png?alt=media&token=7c0fab97-1504-4137-855c-72863e13e664' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/bagger-memory%2FPHOTO-2020-04-26-12-59-25.jpg?alt=media&token=ec5661e8-561a-4b20-92d9-e9d98edc4a1f' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/bagger-memory%2Fmobilbagger_hyundai_robex140w_1_800_f4dc6f5329d91414f224f75d8c41e57b.jpg?alt=media&token=608af9b5-d399-41d5-8521-7b1f9bcbbb41' },
];

const imageSets = [
  {
    name: 'Maus & Bär',
    images: cardImagesSet1,
  },
  {
    name: 'Sexy Bär',
    images: cardImagesSet2,
  },
  {
    name: 'Sexy Maus',
    images: cardImagesSet4,
  },
  {
    name: 'Bagger',
    images: cardImagesSet3,
  },
];

const MemoryGame: React.FC = () => {
  const [selectedImageSetIndex, setSelectedImageSetIndex] = useState(0);
  const [cards, setCards] = useState<CardType[]>([]);
  const [firstChoice, setFirstChoice] = useState<CardType | null>(null);
  const [secondChoice, setSecondChoice] = useState<CardType | null>(null);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [matches, setMatches] = useState<number>(0);
  const [turns, setTurns] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [awardedXP, setAwardedXP] = useState<number>(0);
  const { addExperience } = useUserProgress();
  const navigate = useNavigate();

  const currentImages = imageSets[selectedImageSetIndex].images;
  const totalMatches = currentImages.length;

  // Function to calculate XP based on turns
  const calculateXP = (turns: number): number => {
    if (turns <= 20) {
      return 750;
    } else if (turns <= 25) {
      return 500;
    } else {
      return 350;
    }
  };

  // Shuffle cards and start a new game
  const shuffleCards = () => {
    const shuffledCards = [...currentImages, ...currentImages]
      .sort(() => Math.random() - 0.5)
      .map((card, index) => ({
        ...card,
        id: index,
        matched: false,
      }));

    setCards(shuffledCards);
    setFirstChoice(null);
    setSecondChoice(null);
    setMatches(0);
    setTurns(0);
    setDisabled(false);
    setIsModalOpen(false);
  };

  // Handle a card choice
  const handleChoice = (card: CardType) => {
    if (firstChoice && card.id === firstChoice.id) {
      // Same card clicked twice; ignore the click
      return;
    }

    firstChoice ? setSecondChoice(card) : setFirstChoice(card);
  };

  // Compare two selected cards
  useEffect(() => {
    if (firstChoice && secondChoice) {
      setDisabled(true);
      if (firstChoice.src === secondChoice.src) {
        // Cards match
        setCards((prevCards) =>
          prevCards.map((card) =>
            card.src === firstChoice.src ? { ...card, matched: true } : card
          )
        );
        setMatches((prevMatches) => prevMatches + 1);
        resetTurn();
      } else {
        // Cards do not match
        setTimeout(() => resetTurn(), 1000);
      }
    }
  }, [firstChoice, secondChoice]);

  // Reset choices & increase turn
  const resetTurn = () => {
    setFirstChoice(null);
    setSecondChoice(null);
    setTurns((prevTurns) => prevTurns + 1);
    setDisabled(false);
  };

  // Check if the game is won and show modal
  useEffect(() => {
    if (matches === totalMatches) {
      const xp = calculateXP(turns);
      setAwardedXP(xp);
      setIsModalOpen(true);
      addExperience(xp);
    }
  }, [matches, turns, totalMatches]);

  // Start a new game automatically and when the image set changes
  useEffect(() => {
    shuffleCards();
  }, [selectedImageSetIndex]);

  return (
    <>
      <ScrollingHeader>
        <BackButton className="bg-black" />
      </ScrollingHeader>
      <div className="min-w-[360px] bg-jam-glass min-h-screen flex flex-col items-center py-4 pt-20">
        <h1 className="text-2xl font-bold mb-4 text-white">Maus & Bär Memory</h1>
        { matches === 0 &&
          (
            <div className="flex gap-x-2 items-baseline">
              <p className="text-lg mb-2 text-white">Bilderset:</p>
              <select
                value={selectedImageSetIndex}
                onChange={(e) => setSelectedImageSetIndex(Number(e.target.value))}
                className="mb-4 px-2 rounded bg-white text-black"
              >
                {imageSets.map((set, index) => (
                  <option key={index} value={index}>
                    {set.name}
                  </option>
                ))}
              </select>
            </div>
          )
        }
        
        <p className="text-lg mb-2 text-white">
          Versuche: {turns} | Gefunden: {matches}/{currentImages.length}
        </p>
        <div className="grid grid-cols-3 gap-2 px-4">
          {cards.map((card) => (
            <Card
              key={card.id}
              card={card}
              handleChoice={handleChoice}
              flipped={
                card.id === firstChoice?.id ||
                card.id === secondChoice?.id ||
                card.matched
              }
              disabled={disabled}
            />
          ))}
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-slate-900 text-white p-4 rounded-2xl border-4 border-amber-900 mx-4 text-center">
            <h2 className="text-2xl font-bold mb-4">Du hast gewonnen!</h2>
            <p className="text-lg mb-2">
              Du hast alle Paare in {turns} Versuchen gefunden.
            </p>
            <p className="text-3xl font-bold font-gaming mb-3">
              +{awardedXP}XP
            </p>
            <div className="flex justify-center gap-3">
              <button
                onClick={() => {
                  setIsModalOpen(false);
                  shuffleCards();
                }}
                className="p-3 font-home font-bold text-1xl bg-jam-glass rounded-xl shadow-2xl"
              >
                Nochmal spielen
              </button>
              <button
                className="p-3 font-home font-bold text-1xl bg-jam-glass rounded-xl shadow-2xl"
                onClick={() => navigate(-1)}
              >
                Zurück
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MemoryGame;